<template>
    <div class="advertisingDis">
        <jy-dialog type="form" title="广告分配" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="" prop="routeIds" v-if="dialogVisible">
                        <el-tree :props="props" show-checkbox :data="zzstatusList" node-key="id" ref="orgTree" :default-checked-keys="orgdefaultOpen" @check-change="handleOrgCheckChange">
                        </el-tree>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 树默认展开
            orgdefaultOpen: [],
            // 菜单权限
            orgs: false,

            props: {
                children: 'children',
                label: 'text',
                value: 'id',
            },
            zzstatusList: [],
            diaform: {
                routeIds: [],
                itemCheck: [],
            },
            rules: {},
            dialogVisible: false,
            formLabelWidth: '100px',
        }
    },
    activated() {
        this.getzzAllList()
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
                this.orgs = false
                this.orgdefaultOpen = []
                this.diaform.itemCheck = []
            }
        },
    },
    methods: {
        // 数据权限
        getzzAllList() {
            let url = '/waybillOperate/getTree'
            this.$http.post(url).then(({ detail }) => {
                console.log(detail)
               this.addDisable(detail.list)
                this.zzstatusList = detail.list
            })
        },
        //添加禁用
        addDisable(arr){
            arr.forEach(item=>{
                 if(item.selectType != 3){
                        item.disabled = true
                    }
                    if(item.children&&item.children.length){
                        this.addDisable(item.children)
                    }
            })
        },
        init(option) {
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
                    if (this.diaform.id) {
                        this.getorgdefault(this.diaform.id)
                    }
                })
            }
        },
        getorgdefault(id) {
            let url = '/BaseInfoAd/getAllocations'
            let option = {
                adId: id,
            }
            this.$http.post(url, option).then(({ detail }) => {
                let arr = []
                detail.forEach(item => {
                    arr.push(item.routeId)
                })
                this.orgdefaultOpen = arr
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        // 数据权限树选中
        handleOrgCheckChange() {
            let id = this.$refs.orgTree.getCheckedKeys()
            let name = this.$refs.orgTree.getCheckedNodes()
            console.log(id)
            console.log(name)
            this.diaform.routeIds = id
            let n = []
            name.forEach(item => {
                n.push({id:item.id,selectType:item.selectType})
            })
            console.log(n)
            this.diaform.itemCheck = n
            this.orgs = true
        },
        save() {
            console.log(this.diaform)
            let url = '/BaseInfoAd/allocate'
            let option = JSON.parse(JSON.stringify(this.diaform))
            let arr =[]
             option.itemCheck.forEach(item=>{
                 if(item.selectType == 3){
                      arr.push(item.id)
                 }
             })
            option.routeIds = arr
            if (!this.orgs) {
                option.routeIds = this.orgdefaultOpen
            }
            option.adId = option.id
            this.$http.post(url, option).then(res => {
                this.$message({
                    type: 'success',
                    message: res.msg,
                })
                this.dialogVisible = false
                this.$emit('updateList')
            })
            console.log(this.diaform.orgId)
        },
    },
}
</script>
