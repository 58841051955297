<template>
	<div class="advertiseManagement">
		<jy-query ref="checkForm" :model="formInline">
			<jy-query-item label="广告名称:" prop="name" label-width="100px">
				<el-input v-model="formInline.name" placeholder="请输入"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')">重置</el-button>
				<!-- <el-row>
                    <el-button type="primary" @click="add">新增</el-button>
                    <el-button type="primary" @click="batchdelete">批量删除</el-button>
                </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="primary" @click="batchdelete">批量删除</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
			<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
			<jy-table-column type="selection" width="40"> </jy-table-column>
			<jy-table-column prop="no" label="广告编号" min-width="100"></jy-table-column>
			<jy-table-column prop="name" label="广告名称" min-width="100"></jy-table-column>
			<jy-table-column label="附件" min-width="340">
				<template slot-scope="scope">
					<div>{{ scope.row.attachmentName }}</div>
					<audio class="viewDivNoBorder" controls v-if="isAudio">
						<source :src="scope.row.attachment" type="audio/mpeg" />
						您的浏览器不支持播放
					</audio>
				</template>
			</jy-table-column>
			<jy-table-column prop="createTime" label="创建日期" min-width="160"> </jy-table-column>
			<jy-operate v-bind:list="operateList" width="200"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<addupdate-advertise ref="addupdateAdvertise" @updateList="getlist"></addupdate-advertise>
		<advertising-dis ref="advertisingDis" @updateList="getlist"></advertising-dis>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/waybillOperate/getTree"
			title="选择所属机构"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script>
import addupdateAdvertise from "@/components/pages/admin/basicInformation/advertiseManagement/addupdateAdvertise";
import advertisingDis from "@/components/pages/admin/basicInformation/advertiseManagement/advertisingDis";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			isAudio: true,
			formInline: {
				name: ""
			},
			props: {
				children: "children",
				label: "text",
				value: "id"
			},
			lists: [],
			// 批量删除
			batchdeleteList: [],
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10,
			//  操作
			operateList: []
		};
	},
	components: {
		addupdateAdvertise,
		advertisingDis,
		institutionsTree
	},
	created() {
		this.getlist();
		this.setOperateList();
	},
	methods: {
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.handleEdit
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete
				},
				{
					name: "广告分配",
					icon: "el-icon-delete",
					fun: this.handleAdvertise
				}
			];
			this.operateList = l;
		},
		getlist() {
			let url = "/BaseInfoAd/list";
			let option = {
				...this.formInline,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.isAudio = false;
				this.lists = detail.list;
				this.total = detail.total;
				this.$nextTick(() => {
					this.isAudio = true;
				});
			});
		},
		deleteRecord(brandIdAry) {
			this.$http.post("/BaseInfoAd/delete", brandIdAry).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getlist();
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 导出
		onexport() {
			console.log("导出");
		},
		// 新增
		add() {
			this.$refs.addupdateAdvertise.init();
			console.log("新增");
		},
		// 所属线路
		addInstitutions(data) {
			// console.log(data)
			this.formInline.routeId = data.id;
			this.formInline.routeName = data.text;
		},
		// 组织线路
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 批量删除
		batchdelete() {
			if (this.batchdeleteList.length === 0) {
				this.$message({
					message: "请先选取数据",
					type: "warning"
				});
				return;
			}
			this.$confirm("是否删除选中的广告?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = [];
					this.batchdeleteList.forEach(item => {
						option.push(item.id);
					});
					this.deleteRecord(option);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 批量导入
		batchimport() {},

		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},

		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该广告?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let params = [];
					params.push(row.id);
					this.deleteRecord(params);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 修改
		handleEdit(row) {
			this.$refs.addupdateAdvertise.init(row, "update");
			console.log("编辑");
		},
		// 广告分配
		handleAdvertise(row) {
			console.log(row);
			this.$refs.advertisingDis.init(row);
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		}
	}
};
</script>
<style lang="scss" scoped="advertiseManagement">
.advertiseManagement {
	.viewDivNoBorder {
		padding: 3px;
	}
}
</style>
<style lang="scss"></style>
