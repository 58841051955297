<template>
	<div class="addupdateAdvertise">
		<jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
			<div>
				<el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
					<el-form-item label="所属机构:" prop="orgId">
						<div @click="selectInstitutions">
							<el-input placeholder="请选择" v-model="diaform.orgName" readonly></el-input>
						</div>
					</el-form-item>
					<el-form-item label="广告编号:" prop="no">
						<el-input v-model="diaform.no" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="广告名称:" prop="name">
						<el-input v-model="diaform.name" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="上传附件:" prop="fileList" :label-width="formLabelWidth">
						<el-upload
							class="upload-demo"
							:action="uploadImgUrl"
							:limit="1"
							:file-list="diaform.fileList"
							:on-remove="handleRemove"
							:before-upload="beforeUploadMp3"
							:on-success="handleAvatarSuccess"
							:headers="uploadHeader"
							:on-progress="uploadVideoProcess"
						>
							<el-button size="small" type="primary">点击上传</el-button>
							<div slot="tip" class="el-upload__tip">仅支持mp3格式文件</div>
							<!-- <img v-if="diaform.logoImg" :src="diaform.logoImg" class="avatar"> -->
							<!-- <audio v-if="diaform.logoImg && !audioFlag" class="viewDivNoBorder" controls>
                                <source :src="diaform.logoImg" type="audio/mpeg" /> 您的浏览器不支持播放
                            </audio> -->
							<!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
							<!-- <el-progress v-if="audioFlag" type="circle" :percentage="audioUploadPercent" style="margin-top:30px;"></el-progress> -->
						</el-upload>
					</el-form-item>
					<!-- <div class="log_red">仅支持mp3格式</div> -->
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/system/org/queryOrgTree"
			title="选择机构"
		></institutions-tree>
	</div>
</template>
<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			type: "",
			title: "",
			diaform: {
				no: "",
				name: "",
				orgName: "",
				orgId: "",
				fileList: []
			},
			rules: {
				orgId: [
					{
						required: true,
						message: "请选择所属机构",
						trigger: "change"
					}
				],
				no: [
					{
						required: true,
						message: "请输入广告编号",
						trigger: "blur"
					}
				],
				name: [
					{
						required: true,
						message: "请输入广告名称",
						trigger: "blur"
					}
				],
				fileList: [
					{
						required: true,
						message: "请上传文件",
						trigger: "change"
					}
				]
			},
			dialogVisible: false,
			formLabelWidth: "100px",
			uploadImgUrl: "/ischedule/system/user/uploadLogo",
			uploadHeader: {}
			// audioFlag: false,
			// audioUploadPercent: 0,
		};
	},
	created() {
		this.setUploadHeader();
	},
	activated() {},
	components: {
		institutionsTree
	},
	watch: {
		type: function (val) {
			let t = "";
			switch (val) {
				case "add":
					t = "新增广告";
					break;
				case "update":
					t = "修改广告";
					break;
				default:
					t = "新增广告";
			}
			this.title = t;
		},
		dialogVisible(newview) {
			if (!newview) {
				this.diaform.orgName = "";
				this.diaform.orgId = "";
				this.resetForm("dialogForm");
			}
		}
	},
	methods: {
		setUploadHeader() {
			let header = {
				token: this.$util.getStor("token")
			};
			this.uploadHeader = header;
		},
		init(option, type) {
			this.type = type ? type : "add";
			this.dialogVisible = true;
			if (option) {
				this.$nextTick(() => {
					this.diaform = {
						...this.diaform,
						...JSON.parse(JSON.stringify(option))
					};
					this.diaform.fileList = [
						{
							name: this.diaform.attachmentName,
							url: this.diaform.attachment
						}
					];
				});
			}
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		// 文件限制
		beforeUploadMp3(file) {
			console.log(file);
			var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
			const extension = testmsg === "mp3";
			if (!extension) {
				this.$message({
					message: "上传文件只能是mp3格式！",
					type: "error"
				});
			}
			return extension;
		},
		// 进度
		uploadVideoProcess(event, file) {
			console.log(event, file);
			// this.audioFlag = true
			// this.audioUploadPercent = file.percentage.toFixed(0)
		},
		// 文件上传
		handleAvatarSuccess({ detail }, file) {
			// this.diaform.logoImg = URL.createObjectURL(file.raw)
			// this.$set(this.diaform, 'logoImg', detail)
			// this.diaform.logoImg = detail
			// this.audioFlag = false
			// this.audioUploadPercent = 0
			let url = "/sys/user/shareOutLink";
			this.$http.post(url, { fileName: detail }).then(({ detail }) => {
				let obj = { name: file.name, url: detail };
				this.diaform.fileList = [obj];
				this.$refs.dialogForm.validateField("fileList");
			});
		},
		//文件删除
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.diaform.fileList = [];
			this.$refs.dialogForm.validateField("fileList");
		},
		// 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级机构查询
		addInstitutions(data) {
			// console.log(data)
			this.diaform.orgId = data.orgId;
			this.diaform.orgName = data.name;
		},
		save() {
			this.$refs.dialogForm.validate(valid => {
				if (valid) {
					let url = "/BaseInfoAd";
					if (this.type == "add") {
						url = url + "/create";
					} else {
						url = url + "/update";
					}
					let option = JSON.parse(JSON.stringify(this.diaform));
					option.attachment = option.fileList[0].url;
					option.attachmentName = option.fileList[0].name;
					// console.log(option.roleId)
					this.$http.post(url, option).then(res => {
						this.$message({
							type: "success",
							message: res.msg
						});
						this.dialogVisible = false;
						this.$emit("updateList");
					});
					// console.log(this.diaform.orgId)
				} else {
					return false;
				}
			});
		}
	}
};
</script>
<style lang="scss">
.addupdateAdvertise {
	.dialog_form {
		.avatar-uploader {
			.el-upload.el-upload--text {
				width: 350px;
				height: 100px;
				.viewDivNoBorder {
					padding: 3px;
				}
			}
			.avatar-uploader-icon {
				height: 100px;
				line-height: 100px;
			}
		}
	}
	.log_red {
		color: red;
		margin-left: 100px;
	}
}
</style>
